import React from "react";
import { Helmet } from "react-helmet";
import Navigation from "../components/Layout/Navigation";
import DesktopMenu from "../components/Layout/Navigation/DesktopMenu";
import MenuControls from "../components/Layout/Navigation/MenuControls";
import MobileNavigation from "../components/Layout/Navigation/MobileNavigation";
import TopBar from "../components/Layout/TopBar";
import SEO from "../components/seo";
import { css } from "@emotion/core";
import { graphql, Link, navigate, useStaticQuery } from "gatsby";
import tw from "tailwind.macro";
import GatsbyImage from "gatsby-image";
import { Field, Form, Formik, ErrorMessage } from "formik";
import { MdKeyboardArrowRight } from "react-icons/md";
import * as yup from "yup";
import Footer from "../components/Layout/Footer";
import FifthReusableTiles from "../components/FifthReusableTiles";
import hero from '../images/hero/thanks-image-dog.png';
const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export default function Competition() {
  const { image } = useStaticQuery(graphql`
    {
      image: file(relativePath: { regex: "/comp/hamper-main.png/" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <>
    <Helmet>
    <script
        type="text/javascript"
        src="https://www.greatbritishdogs.co.uk/referrer.js"
      />
    {/* <SEO title="Thanks for entering" keywords={[`great`, `british`, `dogs`]} /> */}
    <SEO
      title="greatbritishdogs.co.uk | Thanks for entering"
      keywords={[`great`, `british`, `dogs`]}
    />
    </Helmet>
      <TopBar></TopBar>
      <Navigation>
        <MenuControls />
        <MobileNavigation></MobileNavigation>
      </Navigation>
      <DesktopMenu />

      <div css={css`
                background-color: #004030 !important;
                `}>
        <div className="container">
          <div
            className="prose max-w-2xl mx-auto py-12"
            css={css`
              h1 {
                ${tw`text-primary font-slab font-bold text-center `}
              }
              h2 {
                ${tw`text-lightCopy font-sans font-bold text-center `}
              }

              .accent {
                ${tw`text-accent`}
              }
            `}
          >
            <div className="text-center">
            <h1 css={css`
                    margin: 60px auto -30px auto !important;
                    color: #F0EED9 !important;
                    font-size: 3.75em !important;
                    font-weight: 800 !important;
              `}>Thanks for your vote</h1>
            <h2 css={css`
                    color: #ffffff !important;
                    margin-bottom: -20px !important;
              `}>Navigate around the site to find out more about Winalot® and to check out more great dog stories</h2><br />
          </div>
          </div>
        </div>
        <div className="text-center" css={css`
                    margin: 0px auto !important;
              `}>
        <img src={hero} alt="Cam & Rolo" css={css`
                    margin: 0px auto !important;
                    text-align: center !important;
              `}/>
        </div>
      </div>
      <div className="bg-pale-100">
        <div className="container" css={css`padding-right:0; padding-left:0;`}>
          <FifthReusableTiles />
        </div>
      </div>
      <Footer />
    </>
  );
}
